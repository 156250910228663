body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; } 
body, button, input, select, textarea { font:12px/1.5tahoma, arial, \5b8b\4f53; } 
h1, h2, h3, h4, h5, h6{ font-size:100%; } 
address, cite, dfn, em, var { font-style:normal; } 
code, kbd, pre, samp { font-family:couriernew, courier, monospace; } 
small{ font-size:12px; } 
ul, ol { list-style:none; } 
a { text-decoration:none; } 
a:hover { text-decoration:underline; } 
sup { vertical-align:text-top; } 
sub{ vertical-align:text-bottom; } 
legend { color:#000; } 
fieldset, img { border:0; } 
button, input, select, textarea { font-size:100%; } 
table { border-collapse:collapse; border-spacing:0; } 

#root{
    margin: 0 auto;
    max-width: 7.50rem;
   
}
h1,h2,h3,h4,h5,h6{
    font-weight: inherit;
}
input{
    background:none;outline:none;border:none;
}
img{
    display: block;
    width: 100%;
    pointer-events: none;
}
button{
    display: block;
    border: none;
    background: rgba(255,255,255,0);
    border: none;
}
.adm-dialog-button{
    color: #000 !important;
}
.adm-dialog-button-bold{
    color: #1677ff !important;
}
