/* 缩放动画 */
.animation_scaling{ 
    animation-name: myfirst;
    animation-duration: 1s;
    animation-iteration-count:infinite;
    -webkit-animation-iteration-count:infinite; /* Safari 和 Chrome */
    @keyframes myfirst
    {
        0%   {transform:scale(1);}
        50%  {transform:scale(1.1)}
        100% {transform:scale(1)}
    }
}
/* 缩放动画V2 */
.animation_scaling2{ 
    animation-name: myfirst2;
    animation-duration: .7s;
    animation-iteration-count:infinite;
    -webkit-animation-iteration-count:infinite; /* Safari 和 Chrome */
    @keyframes myfirst2
    {
        0%   {transform:scale(1);}
        50%  {transform:scale(1.2)}
        100% {transform:scale(1)}
    }
}

// < 字体图标 &#xe615; （后退图标）
// <span className="">&#xe615;</span>
@font-face {
    font-family: 'backIcon';
    // src: url('../font_icon/11.ttf') format('truetype');
}
//  上拉下拉字体图标 
/**
下拉
&#xe616;
上拉
&#xe617;
>
&#xe615;
*/
@font-face {
    font-family: 'pullDownPullUpIcon';
    // src: url('../font_icon/iconfont.ttf') format('truetype');
}
/**
返回
&#xf0292;

相机
&#xe60d;

大于号
&#xe615;

下拉
&#xe616;

上拉
&#xe617;
*/

@font-face {
    font-family: 'TenSans-Bold';
    // src: url('../font_icon/download.otf') format('truetype');
}